.project-banner {
    background-image: url('../Images/PR_rentals_main.jpg');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .project-banner-content {
    text-align: center;
    color: rgba(0, 0, 0, 1); /* Black color for the text */
  }
  
  .project-banner-content h1 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 1); /* Black color for the h1 tag */
  }
  
  .home-button {
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 1); /* Black color for the button border */
    color: rgba(0, 0, 0, 1); /* Black color for the button text */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Light gray color for the button background on hover */
  }

  .project-showcase {
    background-image: url('../Images/Bkg_Proj2.png');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-pre-showcase{
    backgroundColor: transparent; 
    color: #000; 
    fontFamily: Arial, sans-serif; 
    padding: 20px,;
    textAlign: center;

  }

  .breadcrumb-center {
    justify-content: center;
  }

  .breadcrumb-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }