.project-card {
    margin: 1rem;
    width: 30em;
    transition: transform 0.2s; /* Animation for hover effect */
    border-radius: 10px;
	  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
	  overflow: hidden;
    text-align: center;
	  transition: all 0.25s;
	  background-color: rgb(245, 245, 245);
  }
  
  .project-card:hover {
    transform: scale(1.05); /* Slightly enlarge cards on hover */
  }
  
  .card-img-top {
    height: 200px; /* Fixed height for consistency, adjust as needed */
    object-fit: cover; /* Ensure the image covers the card area */
  }
  
  .project-card-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-row-gap: 40px;
    justify-content: center; /* Aligns the grid along the row axis */
    align-items: center; /* Aligns the grid along the column axis */
    margin: 50px 100px 50px 150px; /* Adds 50px of margin to the top and bottom */
  }
  
  .ProjectGridContainer{
    display: flex;
    justify-content: center;
}

    @media screen and (max-width: 950px) {
        .ProjectGridContainer {
            display: list-item;
        }
    }
  /* You might need to adjust the styling based on your specific design needs */
  