.intro-text p {
  text-align: center;
  justify-content: center;
  color: goldenrod;
}

.intro-text h1 {
  text-align: center;
  justify-content: center;
  color: black;
}

.accordion-container {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
}