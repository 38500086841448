.about-banner {
    background-image: url('../Images/PR_AboutUS.jpg');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-banner-content h1 {
    margin-bottom: 20px;
    color: rgb(255, 255, 255); /* Black color for the h1 tag */
  }

  .intro-text p {
    text-align: center;
    justify-content: center;
    color: goldenrod;
  }
  
  .intro-text h1 {
    text-align: center;
    justify-content: center;
    color: black;
  }


  .about-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .about-image {
    width: 40%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-right: 2rem;
  }
  
  .about-content {
    width: 55%;
  }
  
  @media (max-width: 768px) {
    .about-card {
      flex-direction: column;
      align-items: center;
    }
  
    .about-image {
      width: 100%;
      margin-bottom: 2rem;
    }
  
    .about-content {
      width: 100%;
    }
  }