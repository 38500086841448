.testimonial-card {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border: none;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
  }
  
  .rating {
    color: orange; /* Color for the rating stars */
    font-size: 1.25rem; /* Size of the rating stars */
    padding-top: 0.25rem; /* Space between quote and rating stars */
  }
  
  .fas.fa-quote-left {
    color: #ccc; /* Color for the quote icon */
    font-size: 1.5rem; /* Size of the quote icon */
  }
  
  /* Additional styles for spacing, typography, etc. */
  