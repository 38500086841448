.carousel {
    position: relative;
  }
  
  .carousel-item {
    height: 600px; /* Adjust as needed */
  }
  
  .carousel-item img {
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  }
  
  .carousel-caption h3 {
    font-size: 2.5em; /* Make the font bigger */
    font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif; /* Use a stylish font */
  }
  
  .carousel-caption p {
    font-size: 1.5em; /* Make the font bigger */
    font-family: 'Arial', sans-serif; /* Use a stylish font */
  }
  
  .Car_secimg {
    margin-top: 10px;
  }
  
  /* Custom navigation controls */
  .fa-arrow-right, .fa-arrow-left {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 50%; /* Make the icons round */
    padding: 5px;
  }

  .Car_secimg {
    min-width: 130px;
    height: 40px;
    color: #fff;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    outline: none;
    border-radius: 20px;
    border: 2px solid #d90429;
    background: #d90429;
  }
  .Car_secimg:hover {
    background: #fff;
    color: #d90429
  }
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .animate__animated {
    animation-duration: 1s;
  }
  
  .animate__fadeInLeft {
    animation-name: fadeInLeft;
  }
  
  .animate__fadeInRight {
    animation-name: fadeInRight;
  }
  
  .animate__fadeInUp {
    animation-name: fadeInUp;
  }