.showcase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .gallery-container {
    width: 600px;
    height: 400px;
    margin-bottom: 20px;
  }
  
  .tag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .tag-content {
    text-align: center;
  }
  
  .tag-content h3 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  
  .tag-content p {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .tag-content a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: #3b5998;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    margin-right: 10px;
    font-size: 16px;
  }
  
  .tag-content a:last-child {
    margin-right: 0;
  }
  
  .description-container {
    width: 600px;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }

  .rentals-banner-milford {
    background-image: url('../Images/PR_landscape.jpg');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rentals-banner-content-milford h1{
    text-align: center;
    color: rgb(255, 255, 255); /* Black color for the text */
  }

