.footer {
  background-color: #666474fb;
  /* Change as per your design */
  color: #fff;
  padding: 2rem 0;
}

.footer-section h5 {
  color: #1f3fd1f6;
  /* Adjust the color if needed */
  margin-bottom: 1rem;
}

.footer-section p {
  color: #ffffff;
  /* Lighter text color for paragraphs */
  font-size: 1rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  padding: 0.25rem 0;
}

/* Add additional styling for social media icons and links as needed */
.footer img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 10px;
}

.social-icon {
  margin-top: 2px;
  margin-right: 20px;
  margin-left: 20px;
  width: 40px;
}

.footer-button {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #d90429;
  background: #d90429;
  margin-bottom: 10px;
}

.footer-button:hover {
  background: #fff;
  color: #d90429
}


.clock-icon {
  margin-right: 10px;
}


.nav-link {
  position: relative;
  transition: color 0.5s ease;
  text-decoration: none;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background: #DAA520;
  transition: width 0.5s ease;
}

.nav-link:hover {
  color: #ff6347;
}

.nav-link:hover .underline {
  width: 100%;
}

.footer-link {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer-link:hover,
.footer-link:focus,
.footer-link:active {
  color: white;
}