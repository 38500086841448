.custom-navbar {
  background-color: #333;
  transition: background-color 0.3s ease;
}

.custom-navbar:hover {
  background-color: #666666;
}

.custom-brand {
  color: #fff;
  font-size: 1.5em;
}

.custom-link {
  color: #fff;
  transition: color 0.3s ease;
}

.custom-link:hover {
  color: #ddd;
  text-decoration: dashed;
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
}

/* Change the color of the Navbar */
.bg-body-tertiary {
  background-color: #e0e0e0;
  /* Change this to your preferred shade of darker white */
}

/* Change the color and style of the brand */
.navbar-brand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Change the color and style of the links */
.nav-link {
  color: #a79999;
  margin-right: 20px;
  transition: color 0.5s ease;
  text-decoration: none;
}
@media (min-width: 576px) {
  .nav-link {
    font-size: 14px;
  }
}
/* Add a hover effect to the links */
.nav-link:hover {
  color: #c74242;
  text-decoration: none;
}

/* Change the color and style of the dropdown */
.dropdown-toggle {
  color: #fff;
}

/* Add a hover effect to the dropdown */
.dropdown-toggle:hover {
  color: #ddd;
}

/* Change the color and style of the dropdown items */
.dropdown-item {
  color: #333;
}

/* Add a hover effect to the dropdown items */
.dropdown-item:hover {
  color: #666;
  background-color: #f8f9fa;
}

.navbar-brand img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-right: 10px;
}

.company-subtitle {
  color: #DAA520;
  display: block;
  font-size: 0.8em;
  position: relative;
}

.company-subtitle::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, #DAA520, #fff);
}

.button-navbar {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #d90429;
  background: #d90429;
}

.button-navbar:hover {
  background: #fff;
  color: #d90429
}

.nav-link {
  position: relative;
  transition: color 0.5s ease;
  text-decoration: none;
}

.nav-link .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background: #DAA520;
  transition: width 0.5s ease;
}

.nav-link:hover {
  color: #ff6347;
}

.nav-link:hover .underline {
  width: 100%;
}

/* Remove underlines from navbar links */
.navbar a {
  text-decoration: none;
}

.navbar-sm-down {
  /* CSS for screen sizes small and down (<576px) */
}

.navbar-md-down {
  /* CSS for screen sizes medium and down (<768px) */
}

.navbar-lg-down {
  /* CSS for screen sizes large and down (<992px) */
}

.navbar-xl-down {
  /* CSS for screen sizes extra large and down (<1200px) */
}

.navbar-xxl-down {
  /* CSS for screen sizes extra extra large and down (<1400px) */
}