.project-banner {
    background-image: url('../Images/PR_ContactUS.jpg');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .project-banner-content {
    text-align: center;
    color: rgb(252, 251, 251); /* Black color for the text */
  }
  
  .contact-banner-content h1 {
    margin-bottom: 20px;
    color: rgb(30, 68, 196); /* Black color for the h1 tag */
  }
  .contact-section {
    text-align: center;
    margin: 40px 0;
  }
  
  .contact-section h1 {
    font-size: 2em; /* Adjust size as needed */
    margin-bottom: 20px;
    /* Add any additional styling for the title */
  }
  
  .contact-details {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    border-top: 1px solid #ccc; /* Adjust color as needed */
    padding-top: 20px;
  }
  
  .contact-address,
  .contact-info {
    flex-basis: %; /* Adjust width as needed */
  }
  
  .contact-divider {
    height: 100px; /* Adjust height as needed */
    width: 1px;
    background-color: #ccc; /* Adjust color as needed */
    margin: 0 20px; /* Adjust spacing as needed */
  }
  
  .icon-location,
  .icon-phone,
  .icon-email {
    color: red; /* Adjust icon color as needed */
    margin-right: 2px;
  }
  
  .contact-info p,
  .contact-address p {
    text-align: left;
    line-height: 1.5; /* Adjust line spacing as needed */
  }
  
  .contact-info a {
    text-decoration: none;
    color: #000; /* Adjust link color as needed */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contact-details {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-divider {
      display: none;
    }
  
    .contact-address,
    .contact-info {
      flex-basis: auto;
      margin: 10px 0;
    }
  }

  .contact-form-container {
    /* Add styles here for the container, if necessary */
  }
  
  .contact-form-container .form-row {
    margin-bottom: 1rem;
  }
  
  .contact-form-container .form-group {
    background: #f8f8f8; /* Adjust the color to match the form background */
    border: none;
    padding: 10px; /* Adjust the padding to match the form fields spacing */
    border-radius: 0; /* This will remove the rounded borders */
  }
  
  .contact-form-container .form-control {
    border: 1px solid #ccc; /* Adjust the color to match the border */
    box-shadow: none; /* This will remove the default bootstrap shadow */
  }
  
  .contact-form-container .form-control:focus {
    border-color: #aaa; /* Adjust the color to match the focus border */
    box-shadow: none; /* Important to remove shadow on focus */
  }
  
  .contact-form-container .form-label {
    font-weight: bold; /* Makes the label bold */
    color: #333; /* Adjust the color to match the label text color */
  }
  