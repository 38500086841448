.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #666474fb;
  color: #fff;
}

.header__logo {
  flex-grow: 1;
}

.header__social {
  display: flex;
  align-items: center;
}

.header__separator {
  height: 24px;
  width: 1px;
  background-color: #fff;
  margin: 0 16px;
}

.header__contact {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.header__contact p {
  margin: 0;
  margin-left: 8px;
}

@media (max-width: 992px) {
  .header-component {
    display: none;
  }
}

