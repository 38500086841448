.rentals-banner {
    background-image: url('../Images/PR_rentals_main.jpg');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rentals-banner-content {
    text-align: center;
    color: rgba(0, 0, 0, 1); /* Black color for the text */
  }
  
  .rentals-banner-content h1 {
    margin-bottom: 80px;
    color: rgb(255, 255, 255); /* Black color for the h1 tag */
  }
  .breadcrumb-container-rentals {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .breadcrumb-center-rentals {
    justify-content: center;
    color:rgb(255, 255, 255);
    
  }
